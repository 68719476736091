import React from 'react';

import mattenzaun from '../media/jpg/Unbenannt-1.jpg';

function Leistungen() {

    return (
        <div className='leistungen'>
            <img src={mattenzaun} alt='headerImage' />
            <div className='leistungen__beschreibung'>
                <h2>DOPPELSTABMATTEN "LEICHT" 6/5/6</h2>
                    <h3 className='subheader__beschreibung__text'>
                        Der "leichte" Doppelstabmattenzaun eignet sich perfekt für privathäusliche Einfriedungen und einfache Umzäunungen im Industriebereich. Die Matten bieten einen soliden Schutz gegen unbefugten Zutritt und grenzen wirksam Grundstücke voneinander ab. Durch die Verwendung von zwei waagerechten Drähten mit einem Durchmesser von 6 mm ist diese Gittermatte eine günstige Einstiegsvariante.
                    </h3>
                <h2>DOPPELSTABMATTEN "SCHWER" 8/6/8</h2>
                    <h3>
                        Der "schwere" Doppelstabmattenzaun zeichnet sich durch Stabilität und Robustheit aus. Die waagerechten Drähte haben einen Durchmesser von 8 mm. Diese Gittermatten sind heute gleichermaßen bei privaten Immobilienbesitzern wie auch bei industriellen Einsätzen beliebt. Egal ob zum Umzäunen Ihres Gartens oder zum Abgrenzen im Fußballstadion - der Doppelstabzaun ist der Gitterzaun schlechthin.
                    </h3>
            </div>
        </div>
    );
}

export default Leistungen;