import React from 'react';

import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const AutoplaySlider = withAutoplay(AwesomeSlider);

function GalleryComponent(props) {
    
    return (
        <div className='galleryComponent'>
            <AutoplaySlider
                cssModule={CoreStyles}
                bullets={false}
                play={props.play}
                cancelOnInteraction={true}
                interval={2000} 
            >
                {props.images.map(function (image) {
                    return <div data-src={image}/>
                })}
            </AutoplaySlider>
            <h2>{props.name}</h2>
        </div>
    );
}

export default GalleryComponent;