import React from 'react';

function Impressum() {

    return (
        <div className='impressum'>
            <div className='impressum__content'>
                <div className='impressum__text'>
                    <div id='impressum' className='offset'/>
                    <h1 style={{fontSize : "55px"}} >
                        Impressum
                    </h1>
                    <h3>
                        <b>Angaben gemäß § 5 TMG</b><br/>
                        <br/>
                        Sk-Metallbau<br/>
                        Carl-F.-W.-Borgward-Str. 10<br/>
                        28832 Achim<br/>
                        <br/>
                        Handelsregister: *<br/>
                        Registergericht: *<br/>
                        <br/>
                        Vertreten durch:<br/>
                        Björn Kahrs<br/>
                        Daniel Sosna<br/>
                        <br/>
                        <b>Kontakt</b><br/>
                        Telefon: <a href='tel:017634987180'>+49 176 - 34987180</a><br/>
                        E-Mail: <a href='mailto:info@sk-metallbau.eu'>info@sk-metallbau.eu</a><br/>
                        <br/>
                        <b>Umsatzsteuer-ID</b><br/>
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br/>
                        *<br/>
                        <br/>
                        <b>Streitschlichtung</b><br/>
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href='https://ec.europa.eu/consumers/odr' target='_blanck'>https://ec.europa.eu/consumers/odr.</a><br/>
                        Unsere E-Mail-Adresse finden Sie oben im Impressum.<br/>
                        <br/>
                        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br/>
                        <br/>
                        <b>Haftung für Inhalte</b><br/>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/>
                        <br/>
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br/>
                        <br/>
                        <b>Haftung für Links</b><br/>
                        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br/>
                        <br/>
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br/>
                        <br/>
                        <b>Urheberrecht</b><br/>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br/>
                        <br/>
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br/>
                    </h3>
                </div>
                <div className='datenschutz__text'>
                    <div id='datenschutz' className='offset'/>
                    <h1 style={{fontSize : "55px"}}>
                        Datenschutz
                    </h1>
                    <h3>
                        <b>1. Datenschutz auf einen Blick</b><br/>
                        Allgemeine Hinweise<br/>
                        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.<br/>
                        <br/>
                        Datenerfassung auf dieser Website<br/>
                        Wer ist verantwortlich für die Datenerfassung auf dieser Website?<br/>
                        <br/>
                        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.<br/>
                        <br/>
                        Wie erfassen wir Ihre Daten?<br/>
                        <br/>
                        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.<br/>
                        <br/>
                        Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.<br/>
                        <br/>
                        Wofür nutzen wir Ihre Daten?<br/>
                        <br/>
                        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.<br/>
                        <br/>
                        Welche Rechte haben Sie bezüglich Ihrer Daten?<br/>
                        <br/>
                        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.<br/>
                        <br/>
                        Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter „Recht auf Einschränkung der Verarbeitung“.<br/>
                        <br/>
                        Analyse-Tools und Tools von Drittanbietern<br/>
                        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden.<br/>
                        <br/>
                        Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen zu diesen Tools und über Ihre Widerspruchsmöglichkeiten finden Sie in der folgenden Datenschutzerklärung.<br/>
                        <br/>
                        <b>2. Hosting</b><br/>
                        Externes Hosting<br/>
                        Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und sonstige Daten, die über eine Website generiert werden, handeln.<br/>
                        <br/>
                        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).<br/>
                        <br/>
                        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.<br/>
                        <br/>
                        Abschluss eines Vertrages über Auftragsverarbeitung<br/>
                        <br/>
                        Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster geschlossen.<br/>
                        <br/>
                        <b>3. Allgemeine Hinweise und Pflichtinformationen</b><br/>
                        Datenschutz<br/>
                        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br/>
                        <br/>
                        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.<br/>
                        <br/>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br/>
                        <br/>
                        Hinweis zur verantwortlichen Stelle<br/>
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br/>
                        <br/>
                        SK Metallbau UG<br/>
                        Carl-F.-W.-Borgward-Str. 10<br/>
                        28832 Achim<br/>
                        <br/>
                        Vertreten durch:<br/>
                        Geschäftsführer:<br/>
                        Björn Kahrs<br/>
                        Daniel Sosna<br/>
                        <br/>
                        Telefon: <br/>
                        E-Mail: Info@sk-metallbau.de<br/>
                        <br/>
                        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.<br/>
                        <br/>
                        Widerruf Ihrer Einwilligung zur Datenverarbeitung<br/>
                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.<br/>
                        <br/>
                        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)<br/>
                        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).<br/>
                        <br/>
                        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).<br/>
                        <br/>
                        Beschwerderecht bei der zuständigen Aufsichtsbehörde<br/>
                        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.<br/>
                        <br/>
                        Recht auf Datenübertragbarkeit<br/>
                        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.<br/>
                        <br/>
                        SSL- bzw. TLS-Verschlüsselung<br/>
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br/>
                        <br/>
                        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.<br/>
                        <br/>
                        Auskunft, Löschung und Berichtigung<br/>
                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.<br/>
                        <br/>
                        Recht auf Einschränkung der Verarbeitung<br/>
                        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:<br/>
                        <br/>
                        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
                        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.<br/>
                        Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
                        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
                        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.<br/>
                        <br/>
                        <b>4. Datenerfassung auf dieser Website</b><br/>
                        Cookies<br/>
                        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.<br/>
                        <br/>
                        Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.<br/>
                        <br/>
                        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.<br/>
                        <br/>
                        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.<br/>
                        <br/>
                        Soweit andere Cookies (z. B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.<br/>
                        <br/>
                        Server-Log-Dateien<br/>
                        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:<br/>
                        <br/>
                        Browsertyp und Browserversion<br/>
                        verwendetes Betriebssystem<br/>
                        Referrer URL<br/>
                        Hostname des zugreifenden Rechners<br/>
                        Uhrzeit der Serveranfrage<br/>
                        IP-Adresse<br/>
                        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.<br/>
                        <br/>
                        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.<br/>
                        <br/>
                        Kontaktformular<br/>
                        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br/>
                        <br/>
                        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.<br/>
                        <br/>
                        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.<br/>
                        <br/>
                        Anfrage per E-Mail, Telefon oder Telefax<br/>
                        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br/>
                        <br/>
                        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben.<br/>
                        <br/>
                        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.<br/>
                        <br/>
                        Verarbeiten von Daten (Kunden- und Vertragsdaten)<br/>
                        Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme dieser Website (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.<br/>
                        <br/>
                        Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.<br/>
                    </h3>
                </div>
                <div className='agb__text'>
                    <div id='agb' className='offset'/>
                    <h1 style={{fontSize : "55px"}}>
                        Allgemeinen Geschäftsbedingungen
                    </h1>
                    <h3>
                    Die nachfolgenden Allgemeinen Geschäftsbedingungen gelten für sämtliche Angebote und Verträge
und die Lieferungen und Leistungen der Firma SK Metallbau GmbH.
<br/>
<br/>
§ 1 Auftragsannahme / Vertragsschluss
<br/>
Alle Angebote der Firma SK Metallbau GmbH sind bis zur schriftlichen Auftragsannahme durch SK
Metallbau GmbH freibleibend. Erst mit der schriftlichen Auftragsannahme durch die SK Metallbau
GmbH kommt der Vertrag zustande.
<br/>
<br/>
§ 2 Ausführungsfristen
<br/>
Wird die von der Firma SK Metallbau GmbH geschuldete Leistung durch höhere Gewalt, z.B.
Mobilmachung, Krieg, Aufruhr, oder durch ähnliche, nicht von der Firma SK Metallbau GmbH zu
vertretene Ereignisse, z.B. Streik oder Aussperrung, verzögert, so verlängert sich die vereinbarte
Liefer- und Ausführungsfrist um die Dauer dieser Verzögerung, längstens jedoch um 6 Monate.
<br/>
<br/>
§ 3 Gewährleistung / Prüfungspflicht / Lieferung
<br/>
Die gelieferten Gestände sind gemäß § 377 HGB unverzüglich nach Ablieferung/ Auslieferung an den
Besteller / Käufer oder an den von ihm bestimmten Dritten sorgfältig zu untersuchen. Sie gelten
hinsichtlich offensichtlicher Mängel oder anderer Mängel, die bei einer unverzüglichen, sorgfältigen
Untersuchung erkennbar gewesen wären, als vom Besteller/ Käufer genehmigt, wenn SK Metallbau
GmbH nicht binnen 7 Werktagen nach Ablieferung eine schriftliche Mängelrüge zugeht. Hinsichtlich
anderer Mängel gelten die Liefergegenstände als vom Besteller / Käufer genehmigt, wenn die
Mängelrüge SK Metallbau GmbH nicht binnen 7 Werktagen nach dem Zeitpunkt zugeht, in dem sich
der Mangel zeigte; war der Mangel für den Besteller/ Käufer bei normaler Verwendung bereits zu
einem früheren Zeitpunkt erkennbar, ist allein dieser frühere Zeitpunkt für den Beginn der Rügefrist
maßgeblich. Die Rücksendung erfolgt auf Rechnung des Bestellers / Käufers
<br/>
Ist der Auftraggeber Kaufmann, ist er verpflichtet, die Lieferung unverzüglich auf Vollständigkeit und
Mängel zu prüfen und etwaige Beanstandungen unverzüglich, spätestens aber innerhalb einer
Ausschlussfrist von 1 Woche gegenüber der Firma SK Metallbau GmbH schriftlich geltend zu machen.
<br/>
Bei berechtigten Mängelrügen hat die Firma SK Metallbau GmbH die Wahl, entweder die
mangelhaften Gegenstände nachzubessern oder dem Auftraggeber gegen Rücknahme des
beanstandeten Gegenstandes Ersatz zu liefern. Solange die Firma SK Metallbau GmbH ihren
Verpflichtungen auf Behebung der Mängel nachkommt, hat der Auftraggeber nicht das Recht,
Herabsetzung der Vergütung oder Rückgängigmachung des Vertrages zu verlangen, sofern nicht nach
dreimaligem Fehlschlagen der Nacherfüllung vorliegt. Satz 1 und Satz 2 gelten nicht bei
Verbrauchergeschäften über den Bezug beweglicher Sachen.
<br/>
Die Firma SK Metallbau GmbH weist darauf hin, dass die von ihr erstellten Konstruktionen und
gelieferten Modelle zur Vermeidung von Folgeschäden vor Fertigungsbeginn bzw. vor dem Abguss
kontrolliert werden sollten. Aus gleichem Grund sollten die von Firma SK Metallbau GmbH
gelieferten Produkte im Probebetrieb getestet werden.
<br/>
<br/>
§ 4 Haftung
<br/>
Die Firma SK Metallbau GmbH haftet in Fällen des Vorsatzes oder der groben Fahrlässigkeit der
Firma SK Metallbau GmbH oder ihrer Vertreter oder Erfüllungsgehilfen sowie bei einer schuldhaft
verursachten Verletzung des Lebens, des Körpers oder der Gesundheit nach den gesetzlichen
Bestimmungen. Im Übrigen haftet die Firma SK Metallbau GmbH nur nach dem
Produkthaftungsgesetz, wegen der schuldhaften Verletzung wesentlicher Vertragspflichten oder
soweit sie den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit ihrer Leistung
übernommen hat. Die Schadensersatzhaftung für die schuldhafte Verletzung wesentlicher
Vertragspflichten ist jedoch auf den vertragstypischen vorhersehbaren Schaden begrenzt, soweit
nicht zugleich ein anderer der in Satz 1 oder 2 aufgeführten Fälle gegeben ist.
<br/>
Die Regelungen des vorstehenden Absatzes 1 gelten für alle Schadensersatzansprüche (insbesondere
für Schadensersatz neben der Leistung und Schadensersatz statt der Leistung), und zwar gleich aus
welchem Rechtsgrund, insbesondere wegen Mängeln, der Verletzung von Pflichten aus dem
Schuldverhältnis oder aus unerlaubter Handlung. Sie gelten auch für den Anspruch auf Ersatz
vergeblicher Aufwendungen. Die Haftung für Verzug bestimmt sich jedoch nach dem nachstehenden
Absatz 4, die Haftung für Unmöglichkeit nach dem nachstehenden Absatz 5 dieses § 4.
<br/>
Eine Änderung der Beweislast zum Nachteil des Auftraggebers ist mit den vorstehenden Regelungen
nicht verbunden.
<br/>
Die Firma SK Metallbau GmbH haftet bei Verzögerung der Leistung in Fällen des Vorsatzes oder
groben Fahrlässigkeit der Firma SK Metallbau GmbH oder eines Vertreters oder Erfüllungsgehilfen
sowie bei einer schuldhaft verursachten Verletzung des Lebens, des Körpers oder der Gesundheit
nach den gesetzlichen Bestimmungen. In anderen Fällen des Verzugs wird die Haftung der Firma SK
Metallbau GmbH für den Schadensersatz neben der Leistung auf insgesamt 10 % und für den
Schadensersatz statt der Leistung (einschließlich des Ersatzes vergeblicher Aufwendungen) auf
insgesamt 30 % des Wertes der Leistung begrenzt. Weitergehende Ansprüche des Auftraggebers sind
– auch nach Ablauf einer der Firma SK Metallbau GmbH etwa gesetzten Frist zur Leistung –
ausgeschlossen. Die Beschränkung gilt nicht bei schuldhafter Verletzung wesentlicher
Vertragspflichten. Der Schadenersatzanspruch für die schuldhafte Verletzung wesentlicher
Vertragspflichten ist jedoch auf den vertragstypischen vorhersehbaren Schaden begrenzt, soweit
nicht zugleich auch ein Fall nach Satz 1 dieses Absatzes 4 gegeben ist. Ein nach gesetzlichen
Bestimmungen bestehendes Recht des Auftraggebers zum Rücktritt vom Vertrag bleibt hierdurch
unberührt. Eine Änderung der Beweislast zum Nachteil des Auftraggebers ist mit den vorstehenden
Regelungen nicht verbunden.
<br/>
Soweit die von der Firma SK Metallbau GmbH nach dem Vertrag geschuldete Leistung unmöglich ist,
haftet sie in Fällen des Vorsatzes oder der groben Fahrlässigkeit der Firma SK Metallbau GmbH oder
eines Vertreters oder Erfüllungsgehilfen der Firma SK Metallbau GmbH sowie bei einer schuldhaft
verursachten Verletzung des Lebens, des Körpers oder der Gesundheit nach den gesetzlichen
Bestimmungen. In anderen Fällen der Unmöglichkeit beschränkt sich der Anspruch des Auftraggebers
auf Schadensersatz neben und/oder statt der Leistung einschließlich des Ersatzes vergeblicher
Aufwendungen insgesamt auf 30 % des Wertes der Leistung. Weitergehende Ansprüche des
Auftraggebers wegen Unmöglichkeit sind – auch nach Ablauf einer der Firma SK Metallbau GmbH
etwa gesetzten Frist zur Leistung – ausgeschlossen. Die Beschränkung gilt nicht bei schuldhafter
Verletzung wesentlicher Vertragspflichten. Der Schadensersatzanspruch für die schuldhafte
Verletzung wesentlicher Vertragspflichten ist jedoch auf den vertragstypischen vorhersehbaren
Schaden begrenzt, soweit nicht zugleich auch ein Fall nach Satz 1 dieses Absatzes 5 gegeben ist. Ein
nach gesetzlichen Bestimmungen bestehendes Recht des Auftraggebers zum Rücktritt vom Vertrag
bleibt hierdurch unberührt. Eine Änderung der Beweislast zum Nachteil des Auftraggebers ist mit den
vorstehenden Regelungen nicht verbunden.
<br/>
<br/>
§ 5 Pauschalierter Schadenersatz
<br/>
Kündigt der Auftraggeber vor Beginn der Ausführung den Werkvertrag, ist die Firma SK Metallbau
GmbH berechtigt, 10 % der Gesamtauftragssumme als Schadenersatz zu verlangen. Dem
Auftraggeber bleibt das Recht vorbehalten, nachzuweisen, dass ein geringerer oder kein Schaden
entstanden ist.
<br/>
<br/>
§ 6 Abnahme / Fälligkeit der Vergütung
<br/>
Sofern vertraglich eine förmliche Abnahme vorgesehen ist, tritt die Abnahmewirkung auch dann ein,
wenn der Auftraggeber vergeblich und in zumutbarer Weise zur Durchführung der Abnahme
aufgefordert wurde. Die Abnahmewirkung tritt 10 Werktage nach Zugang der Aufforderung ein. Nach
Abnahme ist die Vergütung nach einfacher Rechnungslegung sofort fällig und ohne Skontoabzug zu
zahlen, sofern nichts anderes vereinbart ist.
<br/>
Ist kein individueller Zahlungsplan vereinbart, kann für Teilleistungen in Höhe des Wertzuwachses
eine Abschlagszahlung verlangt werden. Wesentliche Mängel berechtigen nur zum Einbehalt in Höhe
des zweifachen voraussichtlichen Mängelbeseitigungsaufwandes.
<br/>
Wechsel und Schecks werden nur zahlungshalber, nicht an Zahlung statt, angenommen.
<br/>
<br/>
§ 7 Zeichnungen und Entwürfe
<br/>
Zeichnungen und Entwürfe, die von der Firma SK Metallbau GmbH oder in deren Auftrag von Dritten
hergestellt wurden, sind geistiges Eigentum der SK Metallbau GmbH und bei Nichtdurchführung
eines entsprechenden Vertrages bzw. Nichtannahme eines Angebotes unverzüglich zurückzugeben.
Dem Kunden ist es untersagt, die Entwürfe und Zeichnungen in diesem Falle zu benutzen oder an
Dritte weiterzugeben und/oder in sonstiger Weise in das Urheberrecht der Firma SK Metallbau
GmbH einzugreifen. Bei Zuwiderhandlung ist die Firma SK Metallbau GmbH berechtigt, ihren
Aufwand für die Entwürfe und Zeichnungen dem Auftraggeber in Rechnung zu stellen, wobei eigener
Aufwand mit dem bei Angebotsabgabe üblichen Stundenverrechnungssatz berechnet wird. Bei
Streitigkeiten über Aufwand oder Verrechnungssatz entscheidet ein von der Industrie- und
Handelskammer Bremen zu benennender Sachverständiger, der zugleich auch über die Verteilung
der Kosten des Sachverständigenverfahrens entscheidet. Die Geltendmachung darüber
hinausgehender Schäden und von Lizenzansprüchen bleibt vorbehalten
<br/>
<br/>
§ 8 Eigentumsvorbehalt
<br/>
Gelieferte Gegenstände bleiben bis zur vollen Bezahlung sämtlicher Forderungen aus der
Geschäftsbeziehung Eigentum der Firma SK Metallbau GmbH.
<br/>
Der Auftraggeber ist verpflichtet, Standortwechsel der Eigentumsvorbehaltsgegenstände und
Zugriffe Dritter auf die Eigentumsvorbehaltsgegenstände, insbesondere Pfändungen der
Eigentumsvorbehaltsgegenstände, der SK Metallbau GmbH unverzüglich schriftlich anzuzeigen und
die Pfandgläubiger von dem Eigentumsvorbehalt zu unterrichten. Der Auftraggeber ist nicht
berechtigt, die ihm unter Eigentumsvorbehalt gelieferten Gegenstände zu veräußern, zu
verschenken, zu verpfänden oder zur Sicherheit zu übereignen.
<br/>
Erfolgt die Lieferung für einen vom Auftraggeber unterhaltenen Geschäftsbetrieb, so dürfen die
Gegenstände im Rahmen einer ordnungsgemäßen Geschäftsführung weiter veräußert werden. In
diesem Falle werden die Forderungen des Auftraggebers gegen den Abnehmer aus der Veräußerung
bereits jetzt in Höhe des Rechnungswertes des gelieferten Vorbehaltsgegenstandes der SK Metallbau
GmbH abgetreten, die diese Abtretung annimmt. Bei Weiterveräußerung der Gegenstände auf Kredit
hat sich der Auftraggeber gegenüber seinem Abnehmer das Eigentum vorzubehalten. Die Rechte und
Ansprüche aus diesem Eigentumsvorbehalt gegenüber seinem Abnehmer tritt der Auftraggeber
hiermit an die SK Metallbau GmbH ab, die diese Abtretung annimmt.
<br/>
Werden Eigentumsvorbehaltsgegenstände als wesentliche Bestandteile in das Grundstück des
Auftraggebers eingebaut, so tritt der Auftraggeber schon jetzt die aus einer Veräußerung des
Grundstücks oder von Grundstücksrechten entstehenden Forderungen in Höhe des Rechnungswertes
der Eigentumsvorbehaltsgegenstände mit allen Neben-rechten an die SK Metallbau GmbH ab, die
diese Abtretung hiermit annimmt.
<br/>
Werden die Eigentumsvorbehaltsgegenstände vom Auftraggeber bzw. im Auftrag des Auftraggebers
als wesentliche Bestandteile in das Grundstück eines Dritten eingebaut, so tritt der Auftraggeber
schon jetzt gegen den Dritten oder den, den es angeht, etwa entstehende Forderungen auf
Vergütung in Höhe des Rechnungswertes der Eigentums-vorbehaltsgegenstände mit allen
Nebenrechten an die SK Metallbau GmbH ab, die diese Abtretung annimmt. Bei Verarbeitung,
Verbindung und/oder Vermischung der Vorbehaltsgegenstände mit anderen Gegenständen durch
den Auftraggeber steht der SK Metallbau GmbH das Miteigentum an der neuen Sache zu im
Verhältnis des Rechnungswertes der Vorbehaltsgegenstände zum Wert der übrigen Gegenstände.
<br/>
<br/>
§ 9 Montage vor Ort
<br/>
Montagen durch Firma SK Metallbau GmbH können nur durchgeführt werden, wenn die baulichen
Örtlichkeiten ein ungehindertes Arbeiten zulassen und die notwendigen Anlagen und Anschlüsse wie
z.B. Gerüste, Krananlagen, Druckluft- u. Stromzuleitungen für Druckluft- u. Elektrowerkzeuge sowie
die Kosten der Nutzung der Anlagen und Anschlüsse vom Auftraggeber ohne Berechnungen
gegenüber der Firma SK Metallbau GmbH rechtzeitig gestellt werden. Etwaig vor, nach oder bei
Durchführung der Montagen zugesagte Unterstützung durch den Auftraggeber wie z.B.
Rangierarbeiten, Umbauten oder erforderliche Installationen sind ebenfalls vom Auftraggeber ohne
Berechnung gegenüber der Firma SK Metallbau GmbH rechtzeitig zu stellen. Wartezeiten, die darauf
zurückzuführen sind, dass der Auftraggeber die vorstehenden Sätze 1 und 2 nicht beachtet und die
Firma SK Metallbau GmbH hierdurch bedingt mit der Montage vor Ort zu dem vereinbarten Termin
nicht rechtzeitig beginnen oder diese Montage hierdurch bedingt nicht rechtzeitig beenden kann,
werden gesondert berechnet.
<br/>
Montagerechnungen werden wenn nicht anders vereinbart nach Aufwand und Montagebericht
abgerechnet.
<br/>
<br/>
§ 10 Gerichtsstand und sonstige Bestimmungen
<br/>
Sind beide Vertragsparteien Kaufleute, so ist ausschließlicher Gerichtsstand der Geschäftssitz der SK
Metallbau GmbH.
<br/>
Es gelten ausschließlich diese allgemeinen Geschäftsbedingungen. Allgemeine Geschäftsbedingungen
des Auftraggebers gelten nur insoweit, als die Firma SK Metallbau GmbH ihnen ausdrücklich
schriftlich zugestimmt hat.
<br/>
Die Vertragsparteien unterwerfen sich dem geltenden Recht der Bundesrepublik Deutschland, und
zwar auch dann, wenn der Rechtsstreit im Ausland geführt werden muss. Die Bestimmungen des
Wiener Übereinkommens über Verträge über Internationalen Warenkauf vom 11. April 1980 finden
keine Anwendung.
<br/>
Die Firma SK Metallbau GmbH wird nicht an einem Streitbeilegungsverfahren vor einer
Verbraucherschlichtungsstelle im Sinne des VSBG teilnehmen und ist hierzu auch nicht verpflichtet.
(Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz).
<br/>
Sollten einzelne der vorstehenden Bestimmungen ganz oder teilweise unwirksam sein, gleich aus
welchem Grunde, wird die Wirksamkeit des Vertrages und der übrigen Bestimmungen nicht berührt.
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default Impressum;