import React from 'react';
import foto2klein from '../media/jpg/simon-maage-xw-vvvXq3rA-unsplash-small.jpg';

import { motion } from 'framer-motion';

function Header() {
  return (
    <div className='header' id='start'>
      <motion.div
        animate={{ y: -10, opacity: 1 }}
        transition={{ duration: 1.5 }}
        initial={{ y: -40, opacity : 0 }}
        className='header_text'
      >
        <h1 className='header_main_text'>
          SK METALLBAU
        </h1>
        <h2 className='header_sub_text'>
          MEISTERBETRIEB
        </h2>
        <h3 className='header_slogan'>
          Beratung, Handel und Montage
        </h3>
      </motion.div>
      <img src={foto2klein} alt='headerImage' />
    </div>
  );
}

export default Header;