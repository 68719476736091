import { createGlobalStyle } from 'styled-components';

var primaryColor = 'rgb(24, 35, 57)';
var secondaryColor = 'rgb(18, 18, 23)';
var tertiaryColor = 'rgb(72, 72, 72)';
var white = 'rgb(241, 250, 238)';

const GlobalStyle = createGlobalStyle`
    
    body {
        background: ${primaryColor};
    }

    nav {
        background-color: ${secondaryColor};
    }

    h1 h2 h3 h4 h5 {
        color: ${white};
    }

    p {
        color: ${white};
    }

    .header_main_text {
        color: ${white};
    }

    .header_sub_text {
        color: ${white};
    }

    .header_slogan {
        color: ${white};
    }

    .subheader {
        background-color: ${secondaryColor};
    }

    .references {
        background-color: ${primaryColor};
    }

    .reference_main_text {
        color: ${white};
    }

    .reference_see_more {
        color: ${white};
    }

    .team {
        background-color: ${secondaryColor};
    }

    .team_background {
        color: ${white};
    }

    .infos {
        color: ${white};
    }

    .footer {
        background-color: ${tertiaryColor};
    }

    .footer-phone {
        color: ${white};
    }

    .footer-mail {
        color: ${white};
    }

    .footer-adress {
        color: ${white};
    }

    .skills {
        background-color: ${tertiaryColor};
    }

    .skills__small {
        background-color: ${tertiaryColor};
    }

    h1, h2, h3, h4, h5 {
        color: ${white};
    }

    .gallery {
        background-color: ${primaryColor};
    }

    .small_header_main_text {
        color: ${white};
    }

    .small_header_sub_text {
        color: ${white};
    }
`;

export default GlobalStyle;