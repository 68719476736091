import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { slide as Menu  } from 'react-burger-menu';

class BurgerMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    handleStateChange() {
        this.setState({menuOpen: this.state.isOpen})
    }
    
    closeMenu() {
        this.setState({
            menuOpen: false
        });
    }

    render () {
        let startLink;
        let referenceLink;
        let leistungenLink;
        let weLink;

        if (this.props.site === 1) {
            startLink = <AnchorLink href='#start' onClick={() => this.closeMenu()}>Start</AnchorLink>;
            referenceLink = <a href='/referenzen' onClick={() => this.closeMenu()}>Referenzen</a>;
            leistungenLink = <a href='/leistungen' onClick={() => this.closeMenu()}>Leistungen</a>;
            weLink = <AnchorLink href='#team' onClick={() => this.closeMenu()}>Über Uns</AnchorLink>;

        } else if (this.props.site === 2 || this.props.site === 3) {
            startLink = <a href='/#start' onClick={() => this.handleOnClose}>Start</a>;
            referenceLink = <a href='/referenzen' onClick={() => this.handleOnClose}>Referenzen</a>;
            leistungenLink = <a href='/leistungen' onClick={() => this.handleOnClose}>Leistungen</a>;
            weLink = <a href='/#team' onClick={() => this.handleOnClose}>Über Uns</a>;
        }

        return (
            <Menu 
                right
                disableAutoFocus
                isOpen={ this.state.menuOpen }
                onStateChange={(state) => this.handleStateChange(state)}>
                    {startLink}
                    {referenceLink}
                    {leistungenLink}
                    {weLink}
            </Menu>
        );
    }
}

export default BurgerMenu;  