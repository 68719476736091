import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import logo from '../media/logo.svg';

class Navigation extends React.Component {

  render () {
    let startLink;
    let referenceLink;
    let leistungenLink;
    let weLink;

    if (this.props.site === 1) {
        startLink = <h4><AnchorLink href='#start'>Start</AnchorLink></h4>;
        referenceLink = <h4><a href='/referenzen'>Referenzen</a></h4>;
        leistungenLink = <h4><a href='/leistungen'>Leistungen</a></h4>;
        weLink = <h4><AnchorLink href='#team'>Über Uns</AnchorLink></h4>;

    } else if (this.props.site === 2) {
        startLink = <h4><a href='/#start'>Start</a></h4>;
        referenceLink = <h4><a href='/referenzen'>Referenzen</a></h4>;
        leistungenLink = <h4><a href='/leistungen'>Leistungen</a></h4>;
        weLink = <h4><a href='/#team'>Über Uns</a></h4>;
    }
    

    return (
      <nav>
        <a href='/'>
          <img src={logo} className='nav__logo' alt='logo'/>
        </a>
        <div className='nav__links'>
          {startLink}
          {referenceLink}
          {leistungenLink}
          {weLink}
        </div>  
      </nav>
    );
  }
}

export default Navigation;
