import React from 'react';

import beratung from '../media/jpg/beratung.png';
import handel from '../media/jpg/handel.png';
import montage from '../media/jpg/konstruktion.png';

function Skills() {
  return (
    <div className="skills" id='leistung'>
      <div className="skills__content">
      <div>
        <div className='skill'>
          <img src={beratung} alt='beratung_icon'/>
          <h2>Beratung</h2>
          <h3 className='skill_desc_vis'>
            Wir stehen Ihnen mit unserm Fachwissen zu jedem ihrer Anliegen zur Seite. Wir beraten Sie professionell bei Auswahl und der Planung Ihres Projektes sowie beim Kauf Ihres Zaun- oder Torsystems.
          </h3>
        </div>
      </div> 
      <div className='line'/>
      <div>
        <div className='skill'>
          <img src={handel} alt='handel_icon'/>
          <h2>Handel</h2>
          <h3 className='skill_desc_vis'>
            Bei SK Metallbau erhalten Sie Ihre Ware direkt vom Hersteller. So garantieren wir Ihnen eine ständig hochwertige Verarbeitung, beste Qualität und Sicherheit bei allen unseren Produkten.
            SK Metallbau liefert Ihnen hochwertige Zaun- und Torsysteme. Egal ob Doppelstabmatten, Schmuckzaun, Gartentore, Gabionen oder Sichtschutzelemente. Bei uns finden Sie garantiert im Handumdrehen das passende Produkt für Ihren Garten.
          </h3>
        </div>
      </div>
      <div className='line'/>
      <div>
        <div className='skill'>
          <img src={montage} alt='montage_icon'/>
          <h2>Montage</h2>
          <h3 className='skill_desc_vis'>
          Bei uns wird Kundenservice groß geschrieben, wir kümmern uns um ihre Wünsche und montieren ihre Projekt fachgerecht bei ihnen.
          </h3>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Skills;