import React from 'react';
import Gallery from 'react-photo-gallery';

import foto1 from '../media/jpg/schiebetore/IMG_888.jpg';
import foto2 from '../media/jpg/schiebetore/IMG_4017.jpg';
import foto3 from '../media/jpg/zaeune/IMG9.jpg';
import foto4 from '../media/jpg/zaeune/z44.jpg';
import foto5 from '../media/jpg/schiebetore/IMG_4024.jpg';

const photos1 = [
  {
    src: foto1,
    width: 5,
    height: 3,
    alt: 'zaun'
  },
  {
    src: foto2,
    width: 4,
    height: 3,
    alt: 'zaun'
  },
  {
    src: foto3,
    width: 4,
    height:3,
    alt: 'zaun'
  }
]

const photos2 = [
  {
    src: foto4,
    width: 6,
    height: 4,
    alt: 'zaun'
  },
  {
    src: foto5,
    width: 15,
    height: 14,
    alt: 'zaun'
  },
]

export default class References extends React.Component {
  render () {
    return (
      <div className='references'>
        <div className='references__content'>
          <h2 className='reference__headline'>
            UNSERE REFERENZEN
          </h2>
          <Gallery photos={photos1} direction={'row'} />  
          <Gallery photos={photos2} direction={'row'} />
          <h3 className='reference_see_more'>
            <a href='/referenzen'>
              mehr →
            </a>
          </h3>
        </div>
      </div>
    );
  }
}