import React from 'react';

import plakat from '../media/plakat.jpg';

function Subheader() {
  return (
    <div className='subheader'>
        <div className='subheader__content'>
          <h2>SK METALLBAU</h2>
            <h3 className='subheader__text'>
              Als regionales Handwerksunternehmen ist es uns wichtig schnell und verlässlich für unsere Kunden da zu sein.
              <br/>
              Durch Qualität und Fachwissen können wir ihnen eine kompetente Beratung anbieten. Wir bieten ihnen auch  die Möglichkeit zwischen reinem Materialkauf oder fertiger Arbeit zu wählen.
              <br/>
              <br/>
            </h3>
          <h2>WAS WIR IHNEN BIETEN</h2>
            <h3>
              <br/>
              Schiebe Tore, Toranlagen, Zäune, Gittermattenzäune, Sichtschutzwände und Sonderanfertigungen.
              <br/>
              Zudem haben wir uns auf Sicherheitspoller, Antriebs-und Funktechnik spezialisiert bei Schiebetoren und Toranlagen. 
              <br/>
              <br/>
              Dazu zählen z.B  Torsteuerung per App von ihrem Handy, Fingerabdrucksensoren, Telecodiequipment  und Montage mit Anschluss von Torantrieben und deren Zubehör.
              <br/>
              <br/>
              Wir sind ihr zuverlässiger Partner für Private, Gewerbliche und öffentliche Kunden.
            </h3>
          <h2>NEUE ARBEITSPLÄTZE</h2>
          <h3>Wir Schaffen neue Arbeitsplätze mit Mitteln aus dem Europäischen Sozialfonds</h3>
          <div className='plakat_wrapper'>
            <img src={plakat} alt='plakat'/>
          </div>
        </div>
    </div>
  );
}

export default Subheader;