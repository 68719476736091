import React from 'react';

import GalleryComponent from './GalleryComponent';

function importAll(req) {
  return req.keys().map(req);
}

const zaunImages = importAll(require.context('../media/jpg/zaeune', false, /\.(png|jpe?g|svg)$/));
const toreImages = importAll(require.context('../media/jpg/schiebetore', false, /\.(png|jpe?g|svg)$/));

export default class Gallery extends React.Component {
  render() {
    console.log(zaunImages.length);
    return (
      <div className='gallery'>
        <GalleryComponent name='Zäune' images={zaunImages} play={true} />
        <GalleryComponent name='Schiebetore' images={toreImages} play={false} />
      </div>
    );
  }
}