import React, { useEffect } from 'react';

import b1 from '../media/jpg/b1.png';
import b2 from '../media/jpg/b2.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Team() {
  const animation = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      animation.start(variants.visible);
    }
  }, [animation, inView]); 

  const variants = {
    visible: {
      y:0,
      opacity: 1,
      transition: { duration: 0.5}
    },
    hidden: {
      y: -100,
      opacity: 0
    }
  }

  return (
    <div className="team" id='team'>
      <div className="team__content">
        <div className='portrait'>
          <motion.img 
            src={b1}
            alt='portrait'
            ref={ref}
            animate={animation}
            initial={variants.hidden}
            variants={{variants}}  />
          <div className='infos'>
            <h1 className='name'>
              BJÖRN KAHRS
            </h1>
            <h2 className='position'>
              Geschäftsführer <br/>
              Metallbaumeister <br />
              Int. Schweißfachmann <br />
            </h2>
            <div className='details'>
              <div className='mail'>
                <FontAwesomeIcon icon={faEnvelope} />
                <h3>
                  <a href="mailto:info@sk-metallbau.eu">
                    info@sk-metallbau.eu
                  </a>
                </h3>
              </div>
              <div className='phone'>
                <FontAwesomeIcon icon={faPhone} />
                <h3>
                  <a href='tel:017634987180'>
                    +49 176 - 34987180
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className='portrait'>
        <motion.img 
            src={b2}
            alt='portrait'
            ref={ref}
            animate={animation}
            initial={variants.hidden}
            variants={{variants}}  />
          <div className='infos'>
            <h1 className='name'>
              DANIEL SOSNA
            </h1>
            <h2 className='position'>
              Geschäftsführer <br /><br /><br />
            </h2>
            <div className='details'>
              <div className='mail'>
                <FontAwesomeIcon icon={faEnvelope} />
                <h3>
                  <a href="mailto:info@sk-metallbau.eu">
                    info@sk-metallbau.eu
                  </a>
                </h3>
              </div>
              <div className='phone'>
                <FontAwesomeIcon icon={faPhone} />
                <h3>
                  <a href='tel:017655055417'>
                    +49 176 - 55055417
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
